import React from 'react';
import ReviewsRatings from '../../../molecules/ReviewRatings/ReviewsRatings';
import {
  Content,
  ContentWrapper,
  Heading,
  Kicker,
  ReferenceWrapper,
} from '../ConversionPanelStyles';

const ConversionReviews = ({
  conversionButton,
  heading,
  headingKicker,
  size,
  theme,
  variant,
}) => (
  <ContentWrapper>
    <Content align="left">
      {headingKicker && <Kicker theme={theme}>{headingKicker}</Kicker>}
      {heading && (
        <Heading theme={theme} size={size} variant={variant}>
          {heading}
        </Heading>
      )}
      {conversionButton}
    </Content>
    <ReferenceWrapper>
      <ReviewsRatings />
    </ReferenceWrapper>
  </ContentWrapper>
);

export default ConversionReviews;
